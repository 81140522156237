<template>
  <div class="no-data-box" :style="{'width': width+'px'}">
    <div class="no-rold-data">
      <p class="no-rold-img"></p>
      <p class="no-rold-tip">暂无更多内容</p>
    </div>
  </div>

</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: "1200"
    }
  }
};
</script>
<style lang="less">
.no-data-box {
  position: relative;
  margin: 0 auto;
  width: 1198px;
  height: 842px;
  background: #ffffff;
  .no-rold-data {
    position: absolute;
    left: 50%;
    top: 250px;
    margin: 0 0 0 -61px;
    width: 122px;
    .no-rold-img {
      width: 122px;
      height: 96px;
      background: url("~@/assets/img/indexImg/nodata.svg") no-repeat;
      background-size: 100% auto;
      margin: 0 auto;
    }
    .no-rold-tip {
      margin-top: 9px;
      text-align: center;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
</style>
