import request from "@/utils/request";
// 中介排行面包屑
export function rankNavigation(data) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/navigation",
    method: "post",
    data
  });
}
// 律师事务所排名
export function rankLawOffice(params) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/rank_law_office",
    method: "get",
    params
  });
}
// 审计机构排名
export function rankAuditingBody(params) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/rank_auditing_body",
    method: "get",
    params
  });
}
// 资产评估机构排名 year
export function rankAssetAppraisalAgency(params) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/rank_asset_appraisal_agency",
    method: "get",
    params
  });
}
// 按发行日总榜 year
export function rankIssueSum(params) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/rank_issue_sum",
    method: "get",
    params
  });
}
// 按发行日首发 year
export function rankIssueSf(params) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/rank_issue_sf",
    method: "get",
    params
  });
}
// 按发行日增发（投行为主承销商） year
export function rankIssueZfCxs(params) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/rank_issue_zf_cxs",
    method: "get",
    params
  });
}
// 按发行日增发（投行为财务顾问） year
export function rankIssueZfXwgw(params) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/rank_issue_zf_xwgw",
    method: "get",
    params
  });
}
// 按发行日配股 year 
export function rankIssuePg(params) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/rank_issue_pg",
    method: "get",
    params
  });
}
// 按发行日优先股 year
export function rankIssueYxg(params) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/rank_issue_yxg",
    method: "get",
    params
  });
}
// 按发行日可转债 year
export function rankIssueKzz(params) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/rank_issue_kzz",
    method: "get",
    params
  });
}
// 按发行日可交换债 year
export function rankIssueKjh(params) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/rank_issue_kjh",
    method: "get",
    params
  });
}
// 按上市日总榜 year
export function rankListSum(params) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/rank_list_sum",
    method: "get",
    params
  });
}
// 按上市日首发 year
export function rankListSf(params) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/rank_list_sf",
    method: "get",
    params
  });
}
// 按上市日增发（投行为主承销商） year
export function rankListZfCxs(params) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/rank_list_zf_cxs",
    method: "get",
    params
  });
}
// 按上市日增发（投行为财务顾问） year
export function rankListZfXwgw(params) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/rank_list_zf_xwgw",
    method: "get",
    params
  });
}
// 按上市日配股 year
export function rankListPg(params) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/rank_list_pg",
    method: "get",
    params
  });
}
// 按上市日优先股 year
export function rankLYxg(params) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/rank_list_yxg",
    method: "get",
    params
  });
}
// 按上市日可转债 year
export function rankListKzz(params) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/rank_list_kzz",
    method: "get",
    params
  });
}
// 按上市日可交换债 year
export function rankListKjh(params) {
  return request({
    url: "/f/cnfic/xhcj/mediationRanking/rank_list_kjh",
    method: "get",
    params
  });
}