<template>
  <div class="list_box">
    <div class="list-nav">
      <page-tab :subNav="subNav"></page-tab>
    </div>
    <div class="cont-box">
      <div class="cont-bg" :data-img="dataImg"></div>
      <div class="cont">
        <div class="control-box">
          <div class="tab-box" v-if="dateFlag">
            <p class="tab" :class="item.flag?'on':''" v-for="(item,index) in dateArr" :key="index" @click="changeDate(item.value)">{{item.label}}</p>
          </div>
          <!-- <div class="time">{{showTime}}</div> -->
          <div class="search-kind">
            <span class="kind-name">年度</span>
            <div class="select-kind" @mouseenter="isYear=true" @mouseleave="isYear=false">
              <div class="choose-kind">
                <input id="choose_kind" v-model="getYear" readonly/>
                <i class="down-icon" :class="isYear?'down':''"></i>
              </div>
              <div class="kind-list" :class="isYear?'active':''">
                <p class="list-text" v-for="(item,index) in yearArr" :key="index" @click="chooseYear(item)">{{item}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="main-box" v-if="!otherFlag">
          <div class="switch-box" v-if="kindFlag">
            <div class="tab" :class="item.flag?'on':''" v-for="(item,index) in kindArr" :key="index" @click="changeKind(item.value)">{{item.label}}</div>
          </div>
          <div class="main-cont" v-if="listData.length>0">
            <div class="head">
              <p>排名</p>
              <p>机构名称</p>
              <p>募集资金（亿元）</p>
              <p>家数</p>
            </div>
            <div class="body">
              <div class="item" v-for="(item,index) in listData" :key="index">
                <div class="p1">
                  <img v-if="item.rank==1" src="@/assets/img/article_list/trophy1.png" width="auto" height="32" alt="">
                  <img v-else-if="item.rank==2" src="@/assets/img/article_list/trophy2.png" width="auto" height="32" alt="">
                  <img v-else-if="item.rank==3" src="@/assets/img/article_list/trophy3.png" width="auto" height="32" alt="">
                  <span v-else>{{item.rank}}</span>
                </div>
                <div class="p2">
                  <span>{{item.companyName}}</span>
                </div>
                <div class="p3">
                  <div class="line">
                    <i :style="{width:(item.serviceSum/moneyTotal*100)+'%'}"></i>
                  </div>
                  <span class="value-num">{{item.actSum}}</span>
                </div>
                <div class="p4">
                  <span class="line value-num">
                    <i :style="{width:(item.serviceIpo/numTotal*100)+'%'}"></i>
                  </span>
                  <span class="value-num">{{item.serviceIpo}}</span>
                </div>
              </div>
            </div>
          </div>
          <NoData v-else :width="'1198'"/>
        </div>
        <div class="other-box" v-else>
          <div class="head-title">
            <p class="head-no head-public">排名</p>
            <p class="head-name head-public">机构名称</p>
            <div class="head-company">
              <p class="head-all-company">服务上市公司家数</p>
              <div class="head-each-company">
                <span>合计</span>
                <span>本期IPO</span>
                <span>已上市公司</span>
              </div>
            </div>
          </div>
          <div class="body-content" v-if="listData.length>0">
            <div class="each-content" v-for="(item,index) in listData" :key="index">
              <p class="px-box">
                <img v-if="item.rank==1" src="@/assets/img/article_list/trophy1.png" width="auto" height="32" alt="">
                <img v-else-if="item.rank==2" src="@/assets/img/article_list/trophy2.png" width="auto" height="32" alt="">
                <img v-else-if="item.rank==3" src="@/assets/img/article_list/trophy3.png" width="auto" height="32" alt="">
                <span v-else>{{item.rank}}</span>
              </p>
              <p class="company-box">
                <span>{{item.companyName}}</span>
              </p>
              <div class="each-num">
                <p class="best-num">
                  <span class="now-num" :style="{width:(item.serviceSum/moneyTotal*100)+'%'}"></span>
                </p>
                <p class="num-val">{{item.serviceSum}}</p>
              </div>
              <div class="each-num">
                <p class="best-num">
                  <span class="now-num" :style="{width:(item.serviceIpo/numTotal*100)+'%'}"></span>
                </p>
                <p class="num-val">{{item.serviceIpo}}</p>
              </div>
              <div class="each-num">
                <p class="best-num">
                  <span class="now-num" :style="{width:(item.serviceListed/listedTotal*100)+'%'}"></span>
                </p>
                <p class="num-val">{{item.serviceListed}}</p>
              </div>
            </div>
          </div>
          <NoData v-else :width="'1200'"/>
        </div>
      </div>
    </div>
    <loginTip :tipMsg="tipMsg"/>
  </div>
</template>
<script>
import "@/assets/css/rank_list.less";
import { rankNavigation,rankLawOffice,rankAuditingBody,rankAssetAppraisalAgency,rankIssueSum,rankIssueSf,rankIssueZfCxs,rankIssueZfXwgw,rankIssuePg,rankIssueYxg,rankIssueKzz,rankIssueKjh,rankListSum,rankListSf,rankListZfCxs,rankListZfXwgw,rankListPg,rankLYxg,rankListKzz,rankListKjh } from '@/api/rank'
import { setCatalogTree } from '@/utils/store-data'
import NoData from './components/noData'
import loginTip from "@/components/loginTip"
import BuryingPont from "@/mixins/buryingPoint.js";
export default {
  components: { NoData,loginTip },
  mixins: [BuryingPont],
  data() {
    return {
      otherFlag: false,
      subNav: [],
      catalogId: '',
      catalogCode: '',
      subId: this.$route.query.subId,
      nowYear: new Date().getFullYear(),
      // showTime: '',
      kindFlag: false,
      dateFlag: false,
      dateIndex: 1,
      dateArr: [{value: 1,label: '按发行日',flag: true},{value: 2,label: '按上市日',flag: false}],
      kindIndex: 1,
      kindArr: [{value: 1,label: '总榜',flag: true},{value: 2,label: '首发',flag: false},{value: 3,label: '增发(主承销商)',flag: false},{value: 4,label: '增发(财务顾问)',flag: false},{value: 5,label: '配股',flag: false},{value: 6,label: '优先股',flag: false},{value: 7,label: '可转债',flag: false},{value: 8,label: '可交换债',flag: false}],
      isYear: false,
      getYear: new Date().getFullYear(),
      yearArr: [],
      listData: [],
      moneyTotal: 0,
      numTotal: 0,
      listedTotal: 0,
      dataImg: 0,
      tipMsg: {
        code: '',
        text: ''
      }
    };
  },
  created() {
    setCatalogTree(this.$store);
    this.getNavList();
    this.getList();
    // 获取当前年份
    for(var i=this.nowYear;i>=2001;i--){
      this.yearArr.push(i);
    }
    // this.showListDate();
  },
  methods: {
    // 数字金额显示格式
    fmoney(num) {  
      if(!/^(\+|-)?(\d+)(\.\d+)?$/.test(num)){alert("wrong!");   return   num;}  
      var   a   =   RegExp.$1,   b   =   RegExp.$2,   c   =   RegExp.$3;  
      var   re   =   new   RegExp().compile("(\\d)(\\d{3})(,|$)");  
      while(re.test(b))   b   =   b.replace(re,   "$1,$2$3");  
      return   a   +""+   b   +""+   c; 
    },
    // 面包屑
    getNavList(){
      var navData = {
        catalogId: this.$route.query.columnId,
        catalogCode: this.$route.params.id,
      }
      rankNavigation(navData).then(res=>{
        this.subNav = res.data.catalogList;
      }).catch(()=>{})
    },
    // 列表数据
    getList(){
      if(this.subId == 477){
        this.otherFlag = true;
        this.dataImg = 2;
        this.kindFlag = false;
        this.dateFlag = false;
        this.getYear = new Date().getFullYear();
        // 律师事务所排名
        rankLawOffice({year:this.getYear}).then(res=>{
          if(res.code == 0){
            this.getListData(res);
          }else{
            this.tipMsg = {code:res.code,text:res.msg}
          }
        }).catch(function(){});
      }else if(this.subId == 476){
        this.otherFlag = true;
        this.dataImg = 1;
        this.kindFlag = false;
        this.dateFlag = false;
        this.getYear = new Date().getFullYear() - 1;
        // 审计机构排名
        rankAuditingBody({year:this.getYear}).then(res=>{
          if(res.code == 0){
            this.getListData(res);
          }else{
            this.tipMsg = {code:res.code,text:res.msg}
          }
        }).catch(function(){});
      }else if(this.subId == 478){
        this.otherFlag = true;
        this.dataImg = 3;
        this.kindFlag = false;
        this.dateFlag = false;
        this.getYear = new Date().getFullYear();
        // 资产评估机构排名
        rankAssetAppraisalAgency({year:this.getYear}).then(res=>{
          if(res.code == 0){
            this.getListData(res);
          }else{
            this.tipMsg = {code:res.code,text:res.msg}
          }
        }).catch(function(){});
      }else if(this.subId == 475){
        this.otherFlag = false;
        this.dataImg = 0;
        this.kindFlag = true;
        this.dateFlag = true;
        this.getYear = new Date().getFullYear();
        for(var j in this.dateArr){
          if(j == 0){
            this.dateArr[j].flag = true;
          }else{
            this.dateArr[j].flag = false;
          }
        }
        for(var i in this.kindArr){
          if(i == 0){
            this.kindArr[i].flag = true;
          }else{
            this.kindArr[i].flag = false;
          }
        }
        // 承销商业务排行榜-按发行日总榜
        rankIssueSum({year:this.getYear}).then(res=>{
          if(res.code == 0){
            this.getListDataSpecial(res);
          }else{
            this.tipMsg = {code:res.code,text:res.msg}
          }
        }).catch(function(){});
      }
    },
    getListData(list){
      this.moneyTotal = 0;
      this.numTotal = 0;
      this.listData = [];
      for(var i in list.data){
        this.listData.push({rank:list.data[i].rank,companyName:list.data[i].companyName,serviceSum:list.data[i].serviceSum||0,actSum:list.data[i].serviceSum||0,serviceIpo:list.data[i].serviceIpo||0,serviceListed:list.data[i].serviceListed||0})
        if(list.data[i].serviceSum>this.moneyTotal){
          this.moneyTotal = list.data[i].serviceSum;
        }
        if(list.data[i].serviceIpo>this.numTotal){
          this.numTotal = list.data[i].serviceIpo
        }
        if(list.data[i].serviceListed>this.listedTotal){
          this.listedTotal = list.data[i].serviceListed
        }
      }
      
      // 排行榜分类埋点
      var kindType='';
      switch (this.subId){
        case '477':
          kindType = '律师事务所业务排行榜';
          break;
        case '476':
          kindType = '审计机构业务排行榜';
          break;
        case '478':
          kindType = '资产评估机构业务排行榜';
          break;
      }
      this.sensors.track( 'LY_view_rank_page',{
        AppName: this.$store.state.AppName,  
        page_id: '', 
        number: list.data.length,
        type: kindType,
        first_level_column: this.getYear+'年',
        is_login: false, 
        platform: this.$store.state.platform
      })
    },
    getListDataSpecial(list){
      this.moneyTotal = 0;
      this.numTotal = 0;
      this.listData = [];
      for(var i in list.data){
        this.listData.push({rank:list.data[i].rank,companyName:list.data[i].companyName,serviceSum:list.data[i].money||0,actSum:this.fmoney(list.data[i].money)||0,serviceIpo:list.data[i].amount||0})
        if(list.data[i].money>this.moneyTotal){
          this.moneyTotal = list.data[i].money;
        }
        if(list.data[i].amount>this.numTotal){
          this.numTotal = list.data[i].amount;
        }
      }
      
      // 承销商业务排行榜分类埋点
      var dateActive = this.dateArr.filter(item=>{
        return item.flag
      })
      var kindActive = this.kindArr.filter(item=>{
        return item.flag
      })
      this.sensors.track( 'LY_view_rank_page',{
        AppName: this.$store.state.AppName,  
        page_id: '', 
        number: list.data.length,
        type: '承销商业务排行榜',
        first_level_column: dateActive[0].label,
        second_level_column: this.getYear+'年',
        third_level_column: kindActive[0].label,
        is_login: false, 
        platform: this.$store.state.platform
      })
    },
    // 时间段显示
    // showListDate(){
    //   if(this.getYear == this.nowYear){
    //     var nowDate = new Date();
    //     var nowMonth = nowDate.getMonth()+1;
    //     var nowDay = nowDate.getDate();
    //     this.showTime = this.getYear+'.1.1~'+this.getYear+'.'+nowMonth+'.'+nowDay;
    //   }else{
    //     this.showTime = this.getYear+'.1.1~'+this.getYear+'.12.31';
    //   }
    // },
    // 选择年份
    chooseYear(year){
      this.isYear = false;
      if(this.getYear != year){
        this.getYear = year;
        if(this.subId == 477){
          // 律师事务所排名
          rankLawOffice({year:this.getYear}).then(res=>{
            if(res.code == 0){
              this.getListData(res);
            }else{
              this.tipMsg = {code:res.code,text:res.msg}
            }
          }).catch(function(){});
        }else if(this.subId == 476){
          // 审计机构排名
          rankAuditingBody({year:this.getYear}).then(res=>{
            if(res.code == 0){
              this.getListData(res);
            }else{
              this.tipMsg = {code:res.code,text:res.msg}
            }
          }).catch(function(){});
        }
        if(this.subId == 478){
          // 资产评估机构排名
          rankAssetAppraisalAgency({year:this.getYear}).then(res=>{
            if(res.code == 0){
              this.getListData(res);
            }else{
              this.tipMsg = {code:res.code,text:res.msg}
            }
          }).catch(function(){});
        }else if(this.subId == 475){
          this.underwriterIn();
        }
        // this.showListDate();
      }
    },
    // 发行日/上市日 切换
    changeDate(index){
      for(var i in this.dateArr){
        if(this.dateArr[i].value == index){
          this.dateArr[i].flag = true;
        }else{
          this.dateArr[i].flag = false;
        }
      }
      this.dateIndex = index;
      this.underwriterIn();
    },
    // 类型改变
    changeKind(index){
      for(var i in this.kindArr){
        if(this.kindArr[i].value == index){
          this.kindArr[i].flag = true;
        }else{
          this.kindArr[i].flag = false;
        }
      }
      this.kindIndex = index;
      this.underwriterIn();
    },
    // 承销商业务排行榜分类查找接口
    underwriterIn(){
      // 按发行日
      if(this.dateIndex == 1){
        switch(this.kindIndex){
          // 总榜
          case 1:
            rankIssueSum({year:this.getYear}).then(res=>{
              if(res.code == 0){
                this.getListDataSpecial(res);
              }else{
                this.tipMsg = {code:res.code,text:res.msg}
              }
            }).catch(function(){});
            break;
          // 首发
          case 2:
            rankIssueSf({year:this.getYear}).then(res=>{
              if(res.code == 0){
                this.getListDataSpecial(res);
              }else{
                this.tipMsg = {code:res.code,text:res.msg}
              }
            }).catch(function(){});
            break;
          // 增发（投行为主承销商）
          case 3:
            rankIssueZfCxs({year:this.getYear}).then(res=>{
              if(res.code == 0){
                this.getListDataSpecial(res);
              }else{
                this.tipMsg = {code:res.code,text:res.msg}
              }
            }).catch(function(){});
            break;
          // 增发（投行为财务顾问）
          case 4:
            rankIssueZfXwgw({year:this.getYear}).then(res=>{
              if(res.code == 0){
                this.getListDataSpecial(res);
              }else{
                this.tipMsg = {code:res.code,text:res.msg}
              }
            }).catch(function(){});
            break;
          // 配股
          case 5:
            rankIssuePg({year:this.getYear}).then(res=>{
              if(res.code == 0){
                this.getListDataSpecial(res);
              }else{
                this.tipMsg = {code:res.code,text:res.msg}
              }
            }).catch(function(){});
            break;
          // 优先股
          case 6:
            rankIssueYxg({year:this.getYear}).then(res=>{
              if(res.code == 0){
                this.getListDataSpecial(res);
              }else{
                this.tipMsg = {code:res.code,text:res.msg}
              }
            }).catch(function(){});
            break;
          // 可转债
          case 7:
            rankIssueKzz({year:this.getYear}).then(res=>{
              if(res.code == 0){
                this.getListDataSpecial(res);
              }else{
                this.tipMsg = {code:res.code,text:res.msg}
              }
            }).catch(function(){});
            break;
          // 可交换债
          case 8:
            rankIssueKjh({year:this.getYear}).then(res=>{
              if(res.code == 0){
                this.getListDataSpecial(res);
              }else{
                this.tipMsg = {code:res.code,text:res.msg}
              }
            }).catch(function(){});
            break;
        }
      }else if(this.dateIndex == 2){
        switch(this.kindIndex){
          // 总榜
          case 1:
            rankListSum({year:this.getYear}).then(res=>{
              if(res.code == 0){
                this.getListDataSpecial(res);
              }else{
                this.tipMsg = {code:res.code,text:res.msg}
              }
            }).catch(function(){});
            break;
          // 首发
          case 2:
            rankListSf({year:this.getYear}).then(res=>{
              if(res.code == 0){
                this.getListDataSpecial(res);
              }else{
                this.tipMsg = {code:res.code,text:res.msg}
              }
            }).catch(function(){});
            break;
          // 增发（投行为主承销商）
          case 3:
            rankListZfCxs({year:this.getYear}).then(res=>{
              if(res.code == 0){
                this.getListDataSpecial(res);
              }else{
                this.tipMsg = {code:res.code,text:res.msg}
              }
            }).catch(function(){});
            break;
          // 增发（投行为财务顾问）
          case 4:
            rankListZfXwgw({year:this.getYear}).then(res=>{
              if(res.code == 0){
                this.getListDataSpecial(res);
              }else{
                this.tipMsg = {code:res.code,text:res.msg}
              }
            }).catch(function(){});
            break;
          // 配股
          case 5:
            rankListPg({year:this.getYear}).then(res=>{
              if(res.code == 0){
                this.getListDataSpecial(res);
              }else{
                this.tipMsg = {code:res.code,text:res.msg}
              }
            }).catch(function(){});
            break;
          // 优先股
          case 6:
            rankLYxg({year:this.getYear}).then(res=>{
              if(res.code == 0){
                this.getListDataSpecial(res);
              }else{
                this.tipMsg = {code:res.code,text:res.msg}
              }
            }).catch(function(){});
            break;
          // 可转债
          case 7:
            rankListKzz({year:this.getYear}).then(res=>{
              if(res.code == 0){
                this.getListDataSpecial(res);
              }else{
                this.tipMsg = {code:res.code,text:res.msg}
              }
            }).catch(function(){});
            break;
          // 可交换债
          case 8:
            rankListKjh({year:this.getYear}).then(res=>{
              if(res.code == 0){
                this.getListDataSpecial(res);
              }else{
                this.tipMsg = {code:res.code,text:res.msg}
              }
            }).catch(function(){});
            break;
        }
      }
    },
  },
  watch: {
    '$route' (to) {
      this.subId=to.query.subId;
      this.getNavList();
      this.getList();
    }
  }
};
</script>
